.App {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  /* Prevent text selection */
  user-select: none;
  -webkit-user-select: none;
  /* Prevent double-tap zooming */
  touch-action: manipulation;
}

.stars-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #050510, #10102a);
  overflow: hidden;
  z-index: 0;
}

.star {
  position: absolute;
  opacity: 1;
  animation: twinkle 4s infinite alternate ease-in-out;
  background-color: var(--star-color, #ffffff);
  box-shadow: 
    0 0 30px 15px var(--star-color, #ffffff),
    0 0 60px 25px var(--star-color, rgba(255, 255, 255, 0.8)),
    0 0 90px 30px var(--star-color, rgba(255, 255, 255, 0.6));
  filter: blur(0.4px) brightness(1.3) contrast(1.2);
  text-shadow: 0 0 20px var(--star-color, #ffffff);
  clip-path: polygon(
    50% 0%, 
    61% 35%, 
    98% 35%, 
    68% 57%, 
    79% 91%, 
    50% 70%, 
    21% 91%, 
    32% 57%, 
    2% 35%, 
    39% 35%
  );
  z-index: 5;
}

@keyframes twinkle {
  0% {
    opacity: 0.7;
    transform: scale(0.9);
    box-shadow: 
      0 0 15px 5px var(--star-color, #ffffff),
      0 0 30px 10px var(--star-color, rgba(255, 255, 255, 0.5));
    filter: blur(0.5px) brightness(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
    box-shadow: 
      0 0 25px 10px var(--star-color, #ffffff),
      0 0 50px 20px var(--star-color, rgba(255, 255, 255, 0.8)),
      0 0 75px 25px var(--star-color, rgba(255, 255, 255, 0.6));
    filter: blur(0.3px) brightness(1.4);
  }
  100% {
    opacity: 0.8;
    transform: scale(1);
    box-shadow: 
      0 0 20px 8px var(--star-color, #ffffff),
      0 0 40px 15px var(--star-color, rgba(255, 255, 255, 0.6));
    filter: blur(0.5px) brightness(1.2);
  }
}

.panel-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
  padding: 16px;
  box-sizing: border-box;
  animation: fadeIn 0.5s ease-out forwards;
}

.panel-hide {
  animation: fadeOut 0.8s ease-in forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.panel {
  background-color: rgba(10, 12, 28, 1);
  border-radius: 12px;
  padding: 24px;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  text-align: center;
  color: white;
  max-width: 400px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  animation: panelAppear 1.2s ease-out forwards;
  opacity: 0;
  transform: scale(0.9) translateY(20px);
}

@keyframes panelAppear {
  0% {
    opacity: 0;
    transform: scale(0.9) translateY(20px);
  }
  70% {
    opacity: 1;
    transform: scale(1.03) translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.panel h2 {
  margin-top: 0;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 16px;
  background: linear-gradient(45deg, #fff, #ffb0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeIn 0.8s ease-out 0.6s forwards;
  opacity: 0;
}

.panel p {
  margin-bottom: 24px;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
  opacity: 0;
  animation: fadeIn 0.8s ease-out 0.9s forwards;
}

.panel-button {
  background: linear-gradient(45deg, #ff80c0, #a0a0ff);
  border: none;
  border-radius: 50px;
  padding: 12px 32px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s, background 0.3s;
  outline: none;
  animation: fadeIn 0.8s ease-out 1.2s forwards, buttonGlow 3s infinite alternate ease-in-out;
  opacity: 0;
  position: relative;
  overflow: hidden;
  /* Prevent selection on long press */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  /* Disable default touch action */
  touch-action: manipulation;
  box-shadow: 0 0 10px rgba(255, 128, 192, 0.5), 0 0 15px rgba(160, 160, 255, 0.3);
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.7);
}

@keyframes buttonGlow {
  0% {
    box-shadow: 0 0 5px rgba(255, 128, 192, 0.5), 0 0 10px rgba(160, 160, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 128, 192, 0.8), 0 0 15px rgba(160, 160, 255, 0.6), 0 0 20px rgba(255, 128, 255, 0.4);
  }
}

/* Button click effect ripple */
.panel-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%, -50%);
  transform-origin: 50% 50%;
  pointer-events: none;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0) translate(-50%, -50%);
    opacity: 0.6;
  }
  25% {
    transform: scale(25, 25) translate(-50%, -50%);
    opacity: 0.6;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40) translate(-50%, -50%);
  }
}

.panel-button:active::after,
.panel-button.touch-active::after {
  animation: ripple 0.6s ease-out forwards;
}

.panel-button:active,
.panel-button.touch-active {
  transform: scale(0.95);
  background: linear-gradient(45deg, #ff60a0, #8080ff);
  animation-play-state: paused;
  box-shadow: 0 0 20px rgba(255, 128, 192, 0.9), 0 0 30px rgba(160, 160, 255, 0.7);
  transition: transform 0.05s ease-out, background 0.1s ease-out, box-shadow 0.1s ease-out;
}

/* Additional state for post-click animation */
.panel-button.clicked {
  animation: buttonPulse 0.3s ease-in-out forwards;
}

@keyframes buttonPulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 20px rgba(255, 128, 192, 0.9), 0 0 30px rgba(160, 160, 255, 0.7);
  }
  50% {
    transform: scale(1.02);
    box-shadow: 0 0 25px rgba(255, 128, 192, 0.9), 0 0 40px rgba(160, 160, 255, 0.7);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(255, 128, 192, 0.5), 0 0 15px rgba(160, 160, 255, 0.3);
  }
}

.panel-button:hover, .panel-button:focus {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(255, 128, 192, 0.4);
}

/* Falling Pictures Styles */
.falling-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.falling-pic {
  position: absolute;
  top: -200px;
  animation: fall linear forwards;
  opacity: 0.85;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  object-fit: cover;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  z-index: 10;
  /* Prevent selection and contextual menu on long-press */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  will-change: transform;
}

.falling-pic.being-touched {
  opacity: 1;
  transform: scale(3) rotate(0deg) !important;
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.6);
  z-index: 100;
  transition: transform 0.1s ease-out, box-shadow 0.1s ease-out;
}

@keyframes pop {
  0% {
    transform: scale(3) rotate(0deg);
    opacity: 1;
  }
  30% {
    transform: scale(3.5) rotate(0deg);
    opacity: 0.9;
  }
  60% {
    transform: scale(2) rotate(0deg);
    opacity: 0.7;
  }
  100% {
    transform: scale(0.1) rotate(0deg);
    opacity: 0;
  }
}

.falling-pic.popping {
  animation: pop 0.2s ease-out forwards !important;
  animation-play-state: running !important;
  transition: none;
}

@keyframes fall {
  0% {
    top: -200px;
    transform: rotate(0deg);
  }
  5% {
    top: -150px;
    transform: rotate(18deg);
  }
  100% {
    top: 110%;
    transform: rotate(360deg);
  }
}

/* Mobile-specific adjustments */
@media (max-width: 480px) {
  .panel {
    padding: 20px;
  }
  
  .panel h2 {
    font-size: 24px;
  }
  
  .panel p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .panel-button {
    padding: 10px 24px;
    font-size: 14px;
  }
  
  .falling-pic {
    max-width: 80px; /* Smaller images on mobile */
  }
}

/* Heart effect styles */
.heart {
  position: absolute;
  width: 20px;
  height: 20px;
  pointer-events: none;
  z-index: 2;
  animation: heartAnim 1.5s ease-out forwards;
  opacity: 0;
}

.heart::before,
.heart::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--heart-color, #ff6b9d);
  border-radius: 50% 50% 0 0;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
  left: 50%;
}

.heart::after {
  transform: rotate(45deg);
  transform-origin: 100% 100%;
  left: 0;
}

@keyframes heartAnim {
  0% {
    transform: translate(-50%, -50%) scale(0.2) rotate(0deg);
    opacity: 0;
  }
  20% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.5) rotate(20deg);
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.panel-button:hover, .panel-button:focus {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(255, 128, 192, 0.4);
}

.panel-button:active {
  transform: scale(0.95);
  background: linear-gradient(45deg, #ff60a0, #8080ff);
  box-shadow: 0 0 15px rgba(255, 128, 192, 0.8);
}

.panel-button.touch-active::after {
  animation: ripple 0.6s ease-out;
}

.panel-button.touch-active {
  transform: scale(0.95);
  background: linear-gradient(45deg, #ff60a0, #8080ff);
  box-shadow: 0 0 15px rgba(255, 128, 192, 0.8);
}

/* Copyright footer styles */
.copyright-footer {
  position: fixed;
  bottom: 6px;
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  z-index: 5;
  text-shadow: 0 0 10px rgba(128, 0, 128, 0.7), 0 0 15px rgba(0, 0, 255, 0.5);
  font-weight: 400;
  letter-spacing: 2px;
  pointer-events: none;
  padding: 8px 0;
  font-family: 'Gill Sans', 'Helvetica Neue', sans-serif;
  backdrop-filter: blur(3px);
  opacity: 0.9;
  transform: translateY(0);
  animation: gentle-float 4s ease-in-out infinite alternate;
}

.copyright-footer::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  height: 22px;
  background: linear-gradient(90deg, transparent, rgba(255, 128, 255, 0.1), rgba(128, 128, 255, 0.1), transparent);
  border-radius: 30px;
  z-index: -1;
}

@keyframes gentle-float {
  0% {
    transform: translateY(0);
    text-shadow: 0 0 10px rgba(128, 0, 128, 0.7), 0 0 15px rgba(0, 0, 255, 0.5);
  }
  100% {
    transform: translateY(-3px);
    text-shadow: 0 0 15px rgba(128, 0, 128, 0.9), 0 0 20px rgba(0, 0, 255, 0.7);
  }
}

